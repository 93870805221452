$(function() {

  var tv_field = $('.television-program-select2');
  tv_field.each(function(i, el){
    $(el).select2({
      placeholder: 'Įrašykite pavadinimą',
      minimumInputLength: 1,
      multiple: true,
      width: '100%',
      language: lt,
      ajax: {
        url: "/television_programs.json",
        dataType: 'JSON',
        quietMillis: 300,
        data: function (term) {
          return {
            q: { id_in_or_name_cont: term.term },
          };
        },
        processResults: function(data) {
          return { results: data.television_programs }
        },
        results: function (data) {
          return { results: data.results };
        }
      },
    });
  })

  tv_field.each(function(){
    var program_ids = $(this).data('selected');
    if (program_ids) {
      program_ids = $.grep(program_ids, function (value) {
        return value !== "" && value !== null && value !== undefined && value !== 0;
      });
    }
    if(!!program_ids){
      fetchProgramSelections($(this), "/television_programs.json", program_ids)
    }
  });

  function fetchProgramSelections(field, url, params){
    if(params.length > 0){
      var select_field = $(field);
      $.ajax({
        type: 'GET',
        url: url,
        data: {
          q: { id_in: params }
        }
      }).then(function (data) {
        data.television_programs.map(function(el, index){
          var option = new Option(el.text, el.id, true, true);
          select_field.append(option).trigger('change');
        });
      });
    }
  }
  
  var ownership_field = $('.television-program-single-select2');
  // var ownership_field = $(document).find('.television-program-single-select2')

  ownership_field.select2({
    placeholder: 'Įrašykite pavadinimą',
    minimumInputLength: 1,
    multiple: false,
    width: '100%',
    language: lt,
    ajax: {
      url: "/television_programs.json",
      dataType: 'JSON',
      quietMillis: 300,
      data: function (term) {
        return {
          q: { id_in_or_name_cont: term.term },
        };
      },
      processResults: function(data) {
        return { results: data.television_programs }
      },
      results: function (data) {
        return { results: data.results };
      }
    },
  });


  ownership_field.each(function(){
    var program_id = $(this).data('selected');
    if(!!program_id){
      fetchOwnershipSelections($(this), "/television_programs.json", program_id)
    }
  });

  function fetchOwnershipSelections(field, url, params){
    if(params){
      var select_field = $(field);
      $.ajax({
        type: 'GET',
        url: url,
        data: {
          q: { id_eq: params }
        }
      }).then(function (data) {
        data.television_programs.map(function(el, index){
          var option = new Option(el.text, el.id, true, true);
          select_field.append(option).trigger('change');
        });
      });
    }
  }
})
